import React from "react";

import {Button, Stack, Typography } from "@mui/material";
import { colors } from '@mui/material';
import { Link, navigate } from "gatsby"

import { FaAndroid, FaMobileAlt } from "react-icons/fa";

import {appUtils} from "../appUtils";

import translateManager from "../translations/translateManager"

import imgDlHaccp from "../images/mycawan-haccp.svg"
import imgDlApps from "../images/mycawan-apps.svg"
import imgDlPoint from "../images/mycawan-pointeuse.svg"
import imgDlMobile from "../images/mycawan-mobile.svg"


export default function AppCard(props) {

  /*
  props infos
    item : data to display
    standalone : if true the button "autres versions" is visible
  */
    
  const { disabled, appData, standalone, isHideOtherVersions } = props;

  const dlFile = (filename) => {
    const link = document.createElement("a");

    let filepath = process.env.APK_DOWNLOAD_PATH_PREFIX + "/prod/" + filename;
    // console.log(filepath);
    link.href = filepath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // latest version
  let version = appUtils.getLatestVersion(appData.list);
  if(version == null) {
    return;
  }
  // console.log("version : ", version)
  let versionLabel = version.label;
  let versionBuild = version.build;
  if(versionBuild == null) {
    versionBuild = versionLabel.replace(/\./g, "");
  }
  // console.log("versionlabel : ", versionLabel)
  // console.log("versionid : ", versionId)
  let color = colors.lightBlue[600];
  if (version.type == "B") {
    color = colors.orange[500];
  }

  let autresVersion = null;

  if(standalone) {
    if(!isHideOtherVersions) {
      autresVersion = (<Typography variant="string" style={{cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em", marginTop: "1em"}} component="div" onClick={() => { navigate(appData.path)} }>{translateManager.getText("OTHER_VERSIONS")}</Typography>)
    }
  }


  // si il n'y a qu'une version disponible on n'affiche pas le bouton "autres versions"
  // const autresVersion = standalone ? 
  //   // (<Button size="small" onClick={() => { navigate(appData.path)} } >{translateManager.getText("OTHER_VERSIONS")}</Button>)
  //   (<Typography variant="string" style={{cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em", marginTop: "1em"}} component="div" onClick={() => { navigate(appData.path)} }>{translateManager.getText("OTHER_VERSIONS")}</Typography>)
  //    :
  //   ""

  let imgDl = imgDlHaccp;
  if(appData.code == "HACCP") {
    imgDl = imgDlHaccp;
  } else if(appData.code == "APPS") {
    imgDl = imgDlApps;
  } else if(appData.code == "POINTEUSE") {
    imgDl = imgDlPoint;
  } else if(appData.code == "MOBILE") {
    imgDl = imgDlMobile;
  }

  let onClickDl = () => {
    if(!disabled) {
      console.log('click enabled');
      dlFile(appData.fileNamePrefix + versionBuild + appData.fileNameSuffix);
    } 
  }

  return (
    <div style={{textAlign: "center", margin: "2em 0", opacity: disabled ? 0.3 : 1}}>
      
        <img style={{cursor: disabled ? "default" : "pointer"}} src={imgDl} onClick={() => onClickDl()}/>
        
        {/* <h2 style={{margin: "0.5em 0"}}>{appData.label}</h2> */}
        <Typography variant="string" component="div" style={{fontFamily: "Lexand Deca light", fontSize: "0.8em"}}>{translateManager.getText("LATEST_VERSION")} {version.label}</Typography>
      
        <Stack 
          style={{margin: "0.5em 0"}}
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center">
        {/* <Button variant="contained" size="small" 
          onClick={() => dlFile(appData.fileNamePrefix + versionId + appData.fileNameSuffix) }
          >{translateManager.getText("DOWNLOAD_BTN")}</Button> */}
          {autresVersion}
        </Stack>
    </div>
  );
}
